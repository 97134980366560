<template>
  <div>
    <div v-for="(group, index) in row[header]" :key="index" class="explorer-tag">
      <template v-if="group.indexOf('QP-') === 0 || group.indexOf('ACC-') === 0 || group.indexOf('QPF') === 0">
        <a href="javascript:void(0)" title="Non Filterable Tag">{{ group }}</a>
      </template>
      <template v-else>
        <a class="tagPellet" href="javascript:void(0)" title="Click to add in Tags Filter"
           @click="triggerFilterChange(group)">
          {{ group }}
        </a>
      </template>
      <template v-if="row[header].length > 1 && index < row[header].length - 1">
        <span class="ml-2"></span>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  props: ['header', 'row', 'result', 'currentTable'],
  methods: {
    triggerFilterChange(newFilter) {
      let filters = this.currentTable.groupFilterExplorer;
      console.log(filters);
      if (!Array.isArray(filters)) {
        filters = [];
      }
      let exist = false;
      filters.forEach((element) => {
        if (element.value === newFilter) {
          exist = true;
        }
      });
      if (!exist) {
        filters.push({text: newFilter, value: newFilter});
        //  let result = this.result;
        this.$set(this.currentTable, 'groupFilterExplorer', filters);
        this.$store.commit('tableChanged', this.currentTable);
        this.$root.$refs.explorerTagFilter.applyFilter(filters);
      }
    },
  },
};
</script>

<style scoped>
.explorer-tag {
  display: inline;
}
</style>